import React from "react";

const Buttons = ({ name }) => {
  // ConsoleHelper(name);
  return (
    <div>
      <button className="px-3 py-1 m-2 bg-gray-300 rounded-lg hover:bg-gray-100">
        {name}
      </button>{" "}
    </div>
  );
};

export default Buttons;
